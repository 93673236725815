.App {
    left: 0;
    top: 0;
    position: fixed;
    overflow-y: scroll;
    text-align: center;
    background: #F0F0FC;
    width: 18.75rem;
    padding: 0;
    margin: 0;
    height: 100%;
}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td, a, span, img { margin: 0; padding: 0; text-shadow: none; }
fieldset, img { border: 0; }
:focus { outline: 0 none; }
address, caption, cite, code, dfn, strong, th, var, optgroup { font-style: normal; font-weight: normal; }
html, body {list-style: none; font-family: 'Microsoft yahei', Arial; }
h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: normal; }
input, button, textarea, select, optgroup, option { font-family: inherit; font-size: inherit; font-style: inherit; font-weight: inherit; }
input, select { border: 0; appearance: none; -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; }
abbr, acronym { border: 0px; font-variant: normal; }
code, kbd, samp, tt { font-size: 100%; }
input, button, textarea, select { *font-size: 100%; }
body { line-height: 1.4; }
ol, ul { list-style: none; }
li { vertical-align: bottom; }
.clearfix:after { clear: both; content: "."; display: block; height: 0; visibility: hidden; }
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/**Part1：Reset***********/
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
}

/* CSS Document */


/**解决页面因网络问题放大问题**/
@media screen and (max-width: 319px) {
    html {
        font-size: 17px;
    }
}

@media screen and (min-width: 320px) and (max-width: 335px) {
    html {
        font-size: 18px
    }
}

@media screen and (min-width: 336px) and (max-width: 351px) {
    html {
        font-size: 19px
    }
}

@media screen and (min-width: 352px) and (max-width: 367px) {
    html {
        font-size: 20px
    }
}

@media screen and (min-width: 368px) and (max-width: 383px) {
    html {
        font-size: 20px
    }
}

@media screen and (min-width: 384px) and (max-width: 399px) {
    html {
        font-size: 22px
    }
}

@media screen and (min-width: 400px) and (max-width: 415px) {
    html {
        font-size: 23px
    }
}

@media screen and (min-width: 416px) and (max-width: 431px) {
    html {
        font-size: 24px
    }
}

@media screen and (min-width: 432px) and (max-width: 447px) {
    html {
        font-size: 25px
    }
}

@media screen and (min-width: 448px) and (max-width: 463px) {
    html {
        font-size: 26px
    }
}

@media screen and (min-width: 464px) and (max-width: 479px) {
    html {
        font-size: 27px
    }
}

@media screen and (min-width: 480px) and (max-width: 495px) {
    html {
        font-size: 28px
    }
}

@media screen and (min-width: 496px) and (max-width: 511px) {
    html {
        font-size: 29px
    }
}

@media screen and (min-width: 512px) and (max-width: 527px) {
    html {
        font-size: 30px
    }
}

@media screen and (min-width: 528px) and (max-width: 543px) {
    html {
        font-size: 31px
    }
}

@media screen and (min-width: 544px) and (max-width: 559px) {
    html {
        font-size: 32px
    }
}

@media screen and (min-width: 560px) and (max-width: 575px) {
    html {
        font-size: 33px
    }
}

@media screen and (min-width: 576px) and (max-width: 591px) {
    html {
        font-size: 34px
    }
}

@media screen and (min-width: 592px) and (max-width: 607px) {
    html {
        font-size: 35px
    }
}

@media screen and (min-width: 608px) and (max-width: 623px) {
    html {
        font-size: 36px
    }
}

@media screen and (min-width: 624px) and (max-width: 639px) {
    html {
        font-size: 37px
    }
}

@media screen and (min-width: 640px) and (max-width: 655px) {
    html {
        font-size: 38px
    }
}

@media screen and (min-width: 656px) and (max-width: 700px) {
    html {
        font-size: 39px
    }
}

@media screen and (min-width: 750px) {
    html {
        font-size: 40px
    }
}

html {
    height: 100%;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column {
    align-items: start;
    display: flex;
    flex-direction: column;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-line-1 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-line-2 {
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}